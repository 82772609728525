import './App.css';
import { IntlProvider } from 'react-intl';
import { RouterProvider } from 'react-router-dom';
import english from './intl/entries/en';
import { getRoutes } from './services/RouterService';
import { UserProvider } from './contexts/userContext';
import { Provider } from 'react-redux';
import configureStore from './redux/store';

const store = configureStore();

function App() {
    return (
        <Provider store={store}>
            <IntlProvider locale={english.locale} messages={english.messages}>
                <UserProvider>
                    <RouterProvider router={getRoutes} />
                </UserProvider>
            </IntlProvider>
        </Provider>
    );
}

export default App;
