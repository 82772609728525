import React, { useEffect, useState } from 'react';
import Page from '../../components/page_structure/page';
import PageHeader from '../../components/page_structure/page_header';
import PageContent from '../../components/page_structure/page_content';
import { EditOutlined, SwapLeftOutlined, UserAddOutlined, UserDeleteOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/es/table';
import { AnyObject } from 'antd/es/_util/type';
import { Button } from 'antd';
import TableC from '../../components/tables/table';
import AddUserModal from '../../components/modals/add_user_modal';
import { empty_user } from '../../services/empty_entities/EmptyUser';
import { User } from '../../models/entities/user';
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { getAllUsers, updateRole } from '../../redux/actions/manage_actions';

function ManagePage(): JSX.Element {
    const dispatch: AppDispatch = useDispatch();

    const [visible, setVisible] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<User>(empty_user);
    const [userPage, setUsersPage] = useState<User[]>([]);

    useEffect(() => {
        const fetchData = () => {
            dispatch(getAllUsers()).then(users => {
                console.log(users)
                setUsersPage(users.users)
            }).catch((error) => {
                console.log(error)
            })
        }
        fetchData()
    }, [dispatch])

    const updateUserRole = async (user: User) => {
        dispatch(updateRole({email: user.email})).then((found) => {
            setUsersPage(prevUsers =>
                prevUsers.map(u =>
                    u._id === user._id ? { ...u, role: found.updated_user.role } : u
                )
            );
        }).catch((error) => {
            console.log(error)
        });
    };

    const columns = () => {
        let columns: ColumnType<AnyObject>[] = []
        const defaultColumns: ColumnType<AnyObject>[] = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            sorter: (a: any, b: any) => String(a.id).localeCompare(String(b.id)),
            ellipsis: true,
        },{
            title: "First Name",
            dataIndex: "firstName",
            key: "firstName",
            sorter: (a:any, b:any) => String(a.firstName).localeCompare(String(b.firstName)),
            ellipsis: true
        },{
            title: "Last Name",
            dataIndex: "lastName",
            key: "lastName",
            sorter: (a:any, b:any) => String(a.lastName).localeCompare(String(b.lastName)),
            ellipsis: true,
        },{
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: (a:any, b:any) => String(a.email).localeCompare(String(b.email)),
            ellipsis: true,
        },
        {
            title: "Role",
            dataIndex: "role",
            key: "role",
            sorter: (a:any, b:any) => String(a.role).localeCompare(String(b.role)),
            ellipsis: true,
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            ellipsis: true,
        }]

        columns = [...columns, ...defaultColumns]

        // if (true) {
        //     columns.push({
        //         title: <>
        //             <Dropdown
        //                 disabled={
        //                     selected_users === 0 || !true
        //                 }
        //                 menu={{items: [
        //                         {
        //                             key: `mass-delete`,
        //                             label: 
        //                             <Button 
        //                                 icon={<DeleteOutlined />} 
        //                                 onClick={handleDelete} 
        //                                 type="text" 
        //                             >
        //                                 Delete
        //                             </Button>
        //                         }
        //                     ]
        //                 }}
        //             >
        //                 <a>
        //                     <Space>
        //                         Actions <DownOutlined />
        //                     </Space>
        //                 </a>
        //             </Dropdown>
        //             {true ? <div style={{fontSize: 11}}>selected {selected_users}</div> : null}
        //         </>,
        //         width: '170px',
        //         dataIndex: 'actions',
        //         key: 'actions'
        //     })
        // }

        return columns
    }

    const setData = () => {
        let data: any = [];
        userPage.forEach((user)=> {
                data.push({
                    id: user._id,
                    firstName: user.firstname,
                    lastName: user.lastname,
                    email: user.email,
                    role: user.role === 3 ? "Employee" : "Admin",
                    actions:
                        <>
                            <Button type='text' disabled={user.role === 1} onClick={() => updateUserRole(user)}>
                                {user.role === 1 || user.role === 2 ? <div><UserAddOutlined /> Remove as Admin </div> : <div><UserDeleteOutlined /> Add as Admin </div>}
                            </Button><br/>
                            <Button 
                                icon={<EditOutlined />} 
                                disabled={user.role === 1}
                                onClick={() => {setSelectedUser(user); setVisible(true);}} 
                                type="text" 
                            >
                                Edit
                            </Button>
                        </>
                });})
  
        return data;
    } 
    
    return (
        <Page title="Manage Users">
            <PageHeader title={`Testing Panda - Manage Users`} extraButton={true} hasPermissions={false}/>
            <PageContent>
            <Button type={"primary"} style={{float: "right", marginRight: 10, marginTop: 10, marginBottom: 10}} onClick={() => setVisible(true)}>Add New</Button>
            <TableC
                columns={columns()}
                data={setData()}
                checkboxEnabled={false}
            />
            <AddUserModal
                visible={visible}
                setVisible={setVisible}
                user={selectedUser}
                setUser={setSelectedUser}
                userPage={userPage}
                setUserPage={setUsersPage}
            />
            </PageContent>
        </Page>
      );
}

export default ManagePage;
