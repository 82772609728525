import { fetchErrorAction, fetchStartAction, fetchSuccessAction } from "./creators/common_actions";
import { AppDispatch } from "../store";
import { User } from "../../models/entities/user";
import { get, post, put } from "../../services/axios_wrapper";

const api_path = '/api/users/';

interface createUserParams {
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    role: boolean;
}

export const getAllUsers = () => (dispatch: AppDispatch): Promise<{users: User[]}> => {
    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getAllUsers`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const createUser = (data: createUserParams) => (dispatch: AppDispatch): Promise<User> => {

    dispatch(fetchStartAction());

    const request: any = post(`${api_path}createUser`, data)
    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());

                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })

}

export const editUser = (new_user:User) => (dispatch: AppDispatch): Promise<User[]> =>{
    dispatch(fetchStartAction());
    
    const request: any = put(`${api_path}editUser`, new_user)    
    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());
                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}

export const updateRole = (data: {email: string}) => (dispatch: AppDispatch): Promise<{updated_user: User}> =>{
    dispatch(fetchStartAction());
    
    const request: any = put(`${api_path}updateRole`, data)    
    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());
                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}
