import React, {createContext, useContext, useState} from 'react';
import LcsManager from '../assets/local_storage/lcs';
import { User } from '../models/entities/user';
import { empty_user } from '../services/empty_entities/EmptyUser';

const lcs_manager = new LcsManager()

const set_user_to_lcs = (user: User) => {
    lcs_manager.set_item_to_lcs(lcs_manager.Keys.user, user)
}
const set_user_token_to_lcs = (token: string) => {
    lcs_manager.set_item_to_lcs(lcs_manager.Keys.user_token, token)
}

const lcsUser = lcs_manager.get_item_from_lcs(lcs_manager.Keys.user);
const lcsUserToken = lcs_manager.get_item_from_lcs(lcs_manager.Keys.user_token);

interface UserContextProps {
    loggedInUser: User,
    setAppContextUser: (user: User) => void,
    userToken: string,
    setUserToken: (token: string) => void,
    resetApp: () => void,
}

const defaultValues: UserContextProps = {
    loggedInUser: lcsUser ? lcsUser : empty_user,
    setAppContextUser: set_user_to_lcs,
    userToken: lcsUserToken ? lcsUserToken : "",
    setUserToken: set_user_token_to_lcs,
    resetApp: () => {},
}

type UserProviderProps = {
    children: React.ReactNode;
};

const UserContext = createContext<UserContextProps>(defaultValues);

export const UserProvider: React.FC<UserProviderProps> = ({children}) => {
    const [loggedInUser, setAppContextUser] = useState<User>(defaultValues.loggedInUser)
    const [userToken, setUserToken] = useState<string>(defaultValues.userToken)

    const set_loggedin_user = (user: User) => {
        setAppContextUser(user)
        lcs_manager.set_item_to_lcs(lcs_manager.Keys.user, user)
    }

    const set_user_token = (token: string) => {
        setUserToken(token)
        lcs_manager.set_item_to_lcs(lcs_manager.Keys.user_token, token)
    }

    const resetApp = () => {
        setAppContextUser(empty_user)
        setUserToken("")
    };

    const value: UserContextProps = {
        loggedInUser,
        setAppContextUser: set_loggedin_user,
        userToken,
        setUserToken: set_user_token,
        resetApp
    };

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = (): UserContextProps => {
    return useContext(UserContext);
};