import React, { useEffect, useState } from 'react';
import Page from '../../components/page_structure/page';
import PageHeader from '../../components/page_structure/page_header';
import PageContent from '../../components/page_structure/page_content';
import { Button, Input, Select, Space } from 'antd';
import { ColumnType } from 'antd/es/table';
import { AnyObject } from 'antd/es/_util/type';
import TableC from '../../components/tables/table';
import {SyncOutlined} from "@ant-design/icons";
import { User } from '../../models/entities/user';
import { getAllUsers } from '../../redux/actions/manage_actions';
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import { getLogs, getServers, getStatuses, updateLogAssignee, updateLogStatus } from '../../redux/actions/log_actions';
import { GroupLog, Status } from '../../models/entities/log';
import { PageResource } from '../../models/dtos/page_resource';
import { createEmptyPage } from '../../services/utils/page_resource_utils';

const {Option} = Select;
const { Search } = Input;

function LogsPage(): JSX.Element {
    const navigate = useNavigate();
    
    const dispatch: AppDispatch = useDispatch();

    const [usersPage, setUsersPage] = useState<User[]>([]);
    const [logsPage, setLogsPage] = useState<PageResource<GroupLog>>(createEmptyPage());
    const [statuses, setStatuses] = useState<Status[]>([]);
    const [servers, setServers] = useState<string[]>([]);
    const [search, setSearch] = useState<string>('')
    const [selectedRisks, setSelectedRisks] = useState<string[]>([])
    const [selectedStatuses, setSelectedStatuses] = useState<string[]>([])
    const [selectedAssignees, setSelectedAssignees] = useState<string[]>([])
    const [selectedTimes, setSelectedTimes] = useState<string[]>([])
    const [selectedServers, setSelectedServers] = useState<string[]>([])
    const [currentPagination, setCurrentPagination] = useState<number>(10);
    const [triggerFetch, setTriggerFetch] = useState<boolean>(false)
    const [maxLogs, setMaxLogs] = useState<number>(0);

    useEffect(() => {
        const fetchData = () => {
            dispatch(getAllUsers()).then(users => {
                setUsersPage(users.users)
            }).catch((error) => {
                console.log(error)
            })
        }
        fetchData()
    }, [dispatch])

    useEffect(() => {
        fetchData();
    }, [dispatch, search, selectedRisks, selectedStatuses, selectedAssignees, selectedTimes, selectedServers, triggerFetch])

    const fetchData = () => {
        dispatch(getLogs(selectedRisks, selectedStatuses, selectedAssignees, search, selectedTimes, selectedServers, currentPagination)).then(logs => {
            setLogsPage(logs)
            setMaxLogs(logs.totalElements)
        }).catch((error) => {
            console.log(error)
        })
    }

    const handleReset = () => {
        setSelectedRisks([])
        setSelectedStatuses([])
        setSelectedAssignees([])
        setSelectedTimes([])
        setSelectedServers([])
        setSearch('')
    }

    useEffect(() => {
        const fetchData = () => {
            dispatch(getServers()).then(servers => {
                setServers(servers.servers)
            }).catch((error) => {
                console.log(error)
            })
        }
        fetchData()
    }, [dispatch])

    useEffect(() => {
        const fetchData = () => {
            dispatch(getStatuses()).then(statuses => {
                setStatuses(statuses.statuses)
            }).catch((error) => {
                console.log(error)
            })
        }
        fetchData()
    }, [dispatch])

    const isChildRoute = useMatch('/logs/:group_id/*');

    // If child route is matched, render only the child
    if (isChildRoute) return <Outlet />;

    const columns = () => {
        let columns: ColumnType<AnyObject>[] = []
        const defaultColumns: ColumnType<AnyObject>[] = [
        {
            title: "ID",
            dataIndex: "_id",
            key: "_id",
            // sorter: (a:any, b:any) => String(a.id).localeCompare(String(b.id)),
            ellipsis: true,
            width: '80px',
        },{
            title: "Server",
            dataIndex: "server",
            key: "server",
            // sorter: (a:any, b:any) => String(a.server).localeCompare(String(b.server)),
            ellipsis: true
        },{
            title: "Service",
            dataIndex: "service",
            key: "service",
            // sorter: (a:any, b:any) => String(a.service).localeCompare(String(b.service)),
            ellipsis: true,
            width: '150px',
        },{
            title: "Risk",
            dataIndex: "risk",
            key: "risk",
            // sorter: (a:any, b:any) => String(a.risk).localeCompare(String(b.risk)),
            ellipsis: true,
            width: '80px',
        },{
            title: "Occurrences",
            dataIndex: "occurrences",
            key: "occurrences",
            // sorter: (a:any, b:any) => String(a.occurrences).localeCompare(String(b.occurrences)),
            ellipsis: true,
        },{
            title: "Title",
            dataIndex: "title",
            key: "title",
            // sorter: (a:any, b:any) => String(a.title).localeCompare(String(b.title)),
            ellipsis: true,
        },{
            title: "Request",
            dataIndex: "request",
            key: "request",
            // sorter: (a:any, b:any) => String(a.request).localeCompare(String(b.request)),
            ellipsis: true
        },{
            title: "Description",
            dataIndex: "description",
            key: "description",
            // sorter: (a:any, b:any) => String(a.description).localeCompare(String(b.description)),
            ellipsis: true
        },{
            title: "Error",
            dataIndex: "error",
            key: "error",
            // sorter: (a:any, b:any) => String(a.error).localeCompare(String(b.error)),
            ellipsis: true,
            width: '100px',
        },
        {
            title: "Created",
            dataIndex: "time_created",
            key: "time_created",
            // sorter: (a:any, b:any) => String(a.time).localeCompare(String(b.time)),
            ellipsis: true
        },{
            title: "Status",
            dataIndex: "status",
            key: "status",
            // sorter: (a:any, b:any) => String(a.status).localeCompare(String(b.status)),
            ellipsis: true
        },{
            title: "Assignee",
            dataIndex: "assignee",
            key: "assignee",
            // sorter: (a:any, b:any) => String(a.assignee).localeCompare(String(b.assignee)),
            ellipsis: true
        }]

        columns = [...columns, ...defaultColumns]

        // if (true) {
        //     columns.push({
        //         title: <>
        //             <Dropdown
        //                 disabled={
        //                     selected_users === 0 || !true
        //                 }
        //                 menu={{items: [
        //                         {
        //                             key: `mass-delete`,
        //                             label: <DeleteOutlined onClick={handleDelete}><DeleteOutlined /> Delete</DeleteOutlined>,
        //                         }
        //                     ]
        //                 }}
        //             >
        //                 <a>
        //                     <Space>
        //                         Actions <DownOutlined />
        //                     </Space>
        //                 </a>
        //             </Dropdown>
        //             {true ? <div style={{fontSize: 11}}>selected {selected_users}</div> : null}
        //         </>,
        //         width: '170px',
        //         dataIndex: 'actions',
        //         key: 'actions'
        //     })
        // }

        return columns
    }

    const handleAssigneeChange = async (event: string, group_id: string) => {
        try {
            // Find the full assignee details from the list of available assignees
            const newAssignee = usersPage.find(assignee => assignee._id === event);
            if (!newAssignee) {
                console.error('Assignee not found');
                return;
            }
    
            // Call the API to update the log's assignee
            await dispatch(updateLogAssignee({ group_id: group_id, assignee: event }));
    
            // Update the logsPage state
            setLogsPage(prevLogsPage => ({
                ...prevLogsPage,
                content: prevLogsPage.content.map(log =>
                    log._id === group_id
                        ? {
                              ...log,
                              assignee: {
                                  _id: newAssignee._id,
                                  firstname: newAssignee.firstname,
                                  lastname: newAssignee.lastname,
                              },
                          }
                        : log
                ),
            }));
        } catch (error) {
            console.error('Error updating assignee: ', error);
        }
    };
    

    const handleStatusChange = async (event: string, group_id: string) => {
        try {
            // Find the full status details from the list of available statuses
            const newStatus = statuses.find(stat => stat._id === event);
            if (!newStatus) {
                console.error('Status not found');
                return;
            }
    
            // Call the API to update the log status
            await dispatch(updateLogStatus({ group_id: group_id, status: event }));
    
            // Update the logsPage state
            setLogsPage(prevLogsPage => ({
                ...prevLogsPage,
                content: prevLogsPage.content.map(log =>
                    log._id === group_id
                        ? {
                              ...log,
                              status: { _id: newStatus._id, value: newStatus.value },
                          }
                        : log
                ),
            }));
        } catch (error) {
            console.error('Error updating status: ', error);
        }
    };
    

    const setData = () => {
        let data: any = [];
            logsPage.content.forEach(log => {
                data.push({
                    _id: <a onClick={() => navigate(`/logs/${log._id}`)}>{log._id}</a>,
                    server: log.server,
                    service: log.service,
                    risk: log.risk,
                    occurrences: log.occurrences,
                    title: log.title,
                    request: log.request,
                    description: log.description, 
                    error: log.error, 
                    time_created: log.time_created,
                    status: 
                      <Select 
                          onChange={(e) => handleStatusChange(e, log._id)} 
                          placeholder="Status"
                          style={{width: 100}}
                          value={log.status.value}>
                         {statuses.map(status => (
                                <Option 
                                    key={status._id}
                                    value={status._id} 
                                    label={`${status.value}`}
                                    >
                                    {`${status.value}`}
                                </Option>
                            ))} 
                      </Select>,
                    assignee:
                       <Select
                            onChange={(e) => handleAssigneeChange(e, log._id)}
                            placeholder="Assignee"
                            style={{ width: 100 }}
                            value={log.assignee._id ? log.assignee.firstname + " " + log.assignee.lastname : null}
                            >
                            {usersPage.map(user => (
                                <Option 
                                    key={user._id}
                                    value={user._id} 
                                    label={`${user.firstname} ${user.lastname}`}
                                    >
                                    {`${user.firstname} ${user.lastname}`}
                                </Option>
                            ))}
                        </Select>})});
  
        return data;
    } 

    const handleRisksChange = (event: string[]) => {
        setSelectedRisks(event);
    };

    const handleStatusesChange = (event: string[]) => {
        setSelectedStatuses(event);
    };

    const handleAssigneesChange = (event: string[]) => {
        setSelectedAssignees(event);
    };

    const handleTimesChange = (event: string[]) => {
        setSelectedTimes(event);
    };

    const handleServersChange = (event: string[]) => {
        setSelectedServers(event);
    };

    return (
        <Page title="Logs">
            <PageHeader title={`Testing Panda - Logs`}/>
                <PageContent>
                    <Select
                        mode="multiple"
                        style={{marginLeft: 10, marginTop: 10, width: 200}}
                        placeholder="Select Server(s)"
                        value={selectedServers}
                        onChange={handleServersChange}
                        optionLabelProp="label"
                    >
                        {servers.map(server => (
                                <Option 
                                    key={server}
                                    value={server} 
                                    label={`${server}`}
                                    >
                                    <Space>{`${server}`}</Space>
                                </Option>
                            ))}
                    </Select>
                    <Select
                        mode="multiple"
                        style={{marginLeft: 10, marginTop: 10, width: 200}}
                        placeholder="Select Risk(s)"
                        value={selectedRisks}
                        onChange={handleRisksChange}
                        optionLabelProp="label"
                        >
                        <Option 
                            key={"1"}
                            value={"1"} 
                            label={`1`}>
                            <Space>1</Space>
                        </Option>
                        <Option 
                            key={"2"}
                            value={"2"} 
                            label={`2`}>
                            <Space>2</Space>
                        </Option>
                        <Option 
                            key={"3"}
                            value={"3"} 
                            label={`3`}>
                            <Space>3</Space>
                        </Option>
                        <Option 
                            key={"4"}
                            value={"4"} 
                            label={`4`}>
                            <Space>4</Space>
                        </Option>
                        <Option 
                            key={"5"}
                            value={"5"} 
                            label={`5`}>
                            <Space>5</Space>
                        </Option>
                    </Select>
                    <Select
                        mode='multiple'
                        style={{marginLeft: 10, marginTop: 10, width: 200}}
                        placeholder="Select Time"
                        value={selectedTimes}
                        onChange={handleTimesChange}
                        optionLabelProp="label"
                        >
                        <Option 
                            key={"current_hour"}
                            value={"current_hour"} 
                            label={`Current hour`}>
                            <Space>Current hour</Space>
                        </Option>
                        <Option 
                            key={"current_day"}
                            value={"current_day"} 
                            label={`Current day`}>
                            <Space>Current day</Space>
                        </Option>
                        <Option 
                            key={"current_week"}
                            value={"current_week"} 
                            label={`Current week`}>
                            <Space>Current week</Space>
                        </Option>
                        <Option 
                            key={"current_month"}
                            value={"current_month"} 
                            label={`Current month`}>
                            <Space>Current month</Space>
                        </Option>
                    </Select>
                    <Select
                        mode="multiple"
                        style={{marginLeft: 10, marginTop: 10, width: 200}}
                        placeholder="Select Status(es)"
                        value={selectedStatuses}
                        onChange={handleStatusesChange}
                        optionLabelProp="label"
                        >
                          {statuses.map(status => (
                                <Option 
                                    key={status._id}
                                    value={status._id} 
                                    label={`${status.value}`}
                                    >
                                    <Space>{`${status.value}`}</Space>
                                </Option>
                            ))}
                    </Select>
                    <Select
                        mode="multiple"
                        style={{marginLeft: 10, marginTop: 10, width: 200}}
                        value={selectedAssignees}
                        onChange={handleAssigneesChange}
                        placeholder="Select Assignnee(s)"
                        optionLabelProp="label"
                    >
                        {usersPage.map(user => (
                                <Option 
                                    key={user._id}
                                    value={user._id} 
                                    label={`${user.firstname} ${user.lastname}`}
                                    >
                                    <Space>{`${user.firstname} ${user.lastname}`}</Space>
                                </Option>
                            ))}
                    </Select>
                    <Search
                        style={{marginLeft: 10, marginTop: 10, width: 200}}
                        value={search}
                        onChange={(value)=>setSearch(value.target.value)}
                        placeholder="Search for log"
                    />
                    <Button
                        icon={<SyncOutlined/>}
                        style={{float: "right", marginRight: 10, marginTop: 10}}
                        onClick={handleReset}
                        loading={false}>Reset</Button>
                    <TableC
                        columns={columns()}
                        data={setData()}
                        checkboxEnabled={false}
                        currentPagination={currentPagination}
                        setCurrentPagination={setCurrentPagination}
                        triggerFetch={triggerFetch}
                        setTriggerFetch={setTriggerFetch}
                        maxLogs={maxLogs}
                    />
                </PageContent>
        </Page>
      );
}

export default LogsPage;
