export default class LcsManager {
    Keys = {
        user: 'LCSLGIU',
        user_token: 'user_token',
        user_perms: 'LCSLGIUP',
    }

    get_item_from_lcs = (item:any) => {
        const local_item = localStorage.getItem(item)
        if(local_item){
            return JSON.parse(local_item)
        }
        return null
    }

    set_item_to_lcs = (key:string, value:any) => {
        localStorage.setItem(key, JSON.stringify(value))
    }

    remove_item_from_lcs = (item:string) =>{
        localStorage.removeItem(item)
    }

    reset_local_storage = (keysToKeep?:string[]) => {
        keysToKeep = keysToKeep || [];

        // Store the values of the keys to keep
        const valuesToKeep: { [key: string]: string | null } = {};

        keysToKeep.forEach(key => {
            const value = localStorage.getItem(key);
            if (value !== null) {
                valuesToKeep[key] = value;
            }
        });

        // Clear all localStorage
        localStorage.clear();

        // Restore the keys to keep
        Object.keys(valuesToKeep).forEach(key => {
            const value = valuesToKeep[key];
            if (value !== null) {
                localStorage.setItem(key, value);
            }
        });
    }

}
const get_item_from_lcs = (item:any) => {
    const local_item = localStorage.getItem(item)
    if(local_item){
        return JSON.parse(local_item)
    }   
    return null
}


const remove_item_from_lcs = (item:string) =>{
    localStorage.removeItem(item)
}

const LcsKeys = {
    user: 'LCSLGIU',
    user_token: 'user_token',
    user_perms: 'LCSLGIUP',
}

export {get_item_from_lcs, remove_item_from_lcs, LcsKeys};
