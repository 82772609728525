import React, { useState } from 'react';
import { Button, Card, Typography, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { useUserContext } from '../../contexts/userContext';
import { User } from '../../models/entities/user';
import { login } from '../../redux/actions/auth_actions';

const { Title, Text } = Typography;

interface UserFound {
  user: User;
  token: string;
}

function LoginPage(): JSX.Element {
  const dispatch: AppDispatch = useDispatch();
  
  const navigate = useNavigate();

  const {setUserToken, setAppContextUser, resetApp} = useUserContext();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string>('');

  // Form submission handler
  const onFinish = (values: any) => {
    console.log('Login Successful:', values);
    navigate('/logs'); // Redirect to /logs after successful login
  };

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(login(email, password))
        .then((userFound: UserFound) => {
            setAppContextUser(userFound.user);
            setUserToken(userFound.token);
            navigate(`/logs`);
        })
        .catch((error) => {
            setError(error);
            resetApp()
        });
};

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f0f2f5' }}>
      <Card
        style={{
          width: 600,
          padding: 30,
          textAlign: 'center',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
        }}
      >
      {/* <div style={{ width: '100%', marginBottom: '10px' }}>
      <Button
        type="text"
        size="large"
        htmlType="submit"
        title='Back to main page'
        style={{
          width: '20%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          transition: 'all 0.3s ease'
        }}
        onClick={() => navigate('/')}
      >
        <ArrowLeftOutlined style={{ fontSize: '16px', marginRight: '8px' }} />
        <span style={{
          opacity: 0,
          transition: 'opacity 0.3s ease',
          marginLeft: '8px'
        }}>
          Back
        </span>
      </Button>
    </div> */}

        <div style={{ width: '100%' }}>
          <img src='./icons/official_logo.png' alt="Testing Panda Logo" style={{ width: 200 }} />
        </div>
        <Title level={2}>Login to Testing Panda Logs Service!</Title>
        <Text type="secondary">Manage and track your logs effortlessly with our system.</Text>

        {/* Form Component */}
        <Form
          name="login_form"
          onFinish={onFinish} // Handle form submission
          style={{ marginTop: 30 }}
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'Please input a valid email address!' },
            ]}
          >
            <Input
              placeholder="Email"
              type="email"
              style={{ width: '60%', borderRadius: '6px' }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'Please input your password!' },
              { min: 6, message: 'Password must be at least 6 characters!' },
            ]}
          >
            <Input.Password
              placeholder="Password"
              style={{ width: '60%', borderRadius: '6px' }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>

          {/* Login Button */}
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            style={{ width: '60%', borderRadius: '6px' }}
            onClick={handleLogin}
            disabled={email.trim() === '' || password.trim() === ''}
          >
            Login
          </Button>
          {error !== '' ? <p style={{ color: 'red' }}>{error}</p> : null}
        </Form>
      </Card>
    </div>
  );
}

export default LoginPage;
