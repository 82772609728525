import React, { useState , Dispatch, useEffect } from 'react';
import { Button, Checkbox, FormInstance, Input, Modal, Switch } from 'antd';
import { Form } from 'antd';
import { CheckOutlined, CloseOutlined, EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { empty_user } from '../../services/empty_entities/EmptyUser';
import { User } from '../../models/entities/user';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { createUser, editUser } from '../../redux/actions/manage_actions';

interface ModalProps {
  visible: boolean;
  setVisible: (is_visible:boolean)=>void;
  user: User,
  setUser: Dispatch<User>,
  userPage: User[],
  setUserPage: Dispatch<User[]>
}

const AddUserModal: React.FC<ModalProps> = ({ visible, setVisible, user, setUser, userPage, setUserPage}) => {

  const dispatch: AppDispatch = useDispatch();

  const [componentSize, setComponentSize] = useState();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [keepWindowOpen, setKeepWindowOpen] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const [form] = Form.useForm();

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,}$/;

  const onFormLayoutChange = ({ size } :any) => {
    setComponentSize(size);
  };

  const is_edit_mode = ():boolean =>{
    return user === empty_user;
  }

  useEffect(() => {
    setFirstName(user.firstname ? user.firstname : '');
    setLastName(user.lastname ? user.lastname : '');
    setEmail(user.email ? user.email : '');
    setIsAdmin(user.role !==3 ? true : false);
    setPassword(user.password ? user.password : "")
}, [user]);

  useEffect(()=>{
    setIsEditMode(user !== empty_user)
}, [dispatch, user])

useEffect(()=>{
  console.log(isAdmin)
}, [dispatch, isAdmin])

useEffect(()=>{
  form.setFieldsValue({
      firstname: firstName,
      lastname: lastName,
      email: email,
      password: password,
      is_admin: isAdmin,
  })
})

  const resetUser = () => {
    setFirstName('')
    setLastName('')
    setEmail('')
    setPassword('')
    setIsAdmin(false)
    setIsEditMode(false)
    setUser(empty_user)
  }

  const onCreate = (values:any) => {
    if (isEditMode) {
        let confirmed = true;
        let update_user_info: User = {  
            _id: user._id,
            firstname: values.firstname ? values.firstname : user.firstname,
            lastname: values.lastname ? values.lastname : user.lastname,
            email: values.email ? values.email : user.email,
            role: isAdmin ? 2 : 3,
        }

        // Uncomment if password handling is required
        // if(values.password){
        //     confirmed = window.confirm('Even if you change the password, the user is forced to change it at the first log in.')
        //     update_user_info.password = values.password
        // }

        if (confirmed) {
            dispatch(editUser(update_user_info)).then(() => {
                if (userPage) {
                    const updatedUsers = userPage.map(u =>
                        u._id === update_user_info._id ? update_user_info : u
                    );
                    setUserPage(updatedUsers);
                }
            }).catch((err) => {
                console.log(err)
            });
            setVisible(false);
        }
    }else{
        let new_user: any = {
            firstname: firstName,
            lastname: lastName,
            password: password,
            email: email,
            role: isAdmin ? 2 : 3,
        }
        dispatch(createUser(new_user))
        .then((foundUser) => {
            const updatedUserPage: any = {
                ...userPage,
                content: [
                    ...userPage,
                    foundUser
                ]
            };
            setUserPage(updatedUserPage); // Ensure setUserPage updates the state correctly

        }).catch((error) => {
            console.log(error)
        })
        if(!values.continue){
            keepWindowOpen ? setVisible(true) : setVisible(false)
        }
    }
    resetUser()
}

  const handleOk = (form:FormInstance<any>) => {
    form
        .validateFields()
        .then(values => {
            // form.resetFields();
            onCreate(values)
    })
    .catch(() => {
        // console.log('Validate Failed:', info);
    });
}

  const handleCancel = () => {
    resetUser()
    setVisible(false)
  };

  const footer_buttons = [
    <Button key="submit_cancel" onClick={handleCancel}>
        Cancel
    </Button>,
    <Button key="submit_ok" type="primary" onClick={() => handleOk(form)}
        disabled={
        firstName === '' || lastName === '' || email === '' || password === '' 
        || (!isEditMode && userPage.some(user => user.email === email))
        || !passwordRegex.test(password)
        }
    >
        {isEditMode? 'Save' : 'Create'} 
    </Button>
  ];


  return (
        <Modal key={'user_modal'} open={visible} title={isEditMode? 'Edit User' : 'Add New User'} onCancel={handleCancel} footer={footer_buttons}>
        {password !== undefined && password !== null && password !== '' && !passwordRegex.test(password) ?
        <p style={{color: '#FF4D4F'}}>Password must contain at least one lowercase letter, one uppercase letter, one special character, and be at least 8 characters long!</p> : null}
        {!isEditMode && userPage.some(user => user.email === email) && <p style={{color: '#FF4D4F'}}>The specific email already exists!</p>}
            <Form form={form}
                  name="createUser"
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 14 }}
                  layout="horizontal"
                  initialValues={{ size: componentSize }}
                  onValuesChange={onFormLayoutChange}
                  size={componentSize}
            >
                <Form.Item  name="firstname" label="Firstname" 
                            rules={[{ required: !isEditMode, message: 'Please input a First Name!' }]}>
                    <Input  value={firstName} placeholder="Enter user first name"
                            onChange={(event) => setFirstName(event.target.value)}/>
                </Form.Item>
                <Form.Item  name="lastname" label="Lastname" 
                            rules={[{ required: !isEditMode, message: 'Please input a Last Name!' }]}>
                    <Input  value={lastName} placeholder="Enter user last name"
                            // disabled={true}
                            onChange={(event) => setLastName(event.target.value)}/>
                </Form.Item>
                <Form.Item  name="email" label="Email"
                            rules={[{ required:  !isEditMode, message: 'Please input an Email!' }]}>
                            
                    <Input  value={email} placeholder="Enter user email"
                            onChange={(event) => setEmail(event.target.value)}/>
                </Form.Item>
                <Form.Item  name="password" label="Password"
                            rules={[{ required: !isEditMode, 
                                    message: 'Please input a Password!' }
                                    ]}  
                            >
                    <Input.Password  value={password} placeholder="Enter user password"
                            onChange={(event) => setPassword(event.target.value)}
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                </Form.Item>
                <Form.Item name="is_admin" label="Admin">
                    <Switch checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={isAdmin}
                            onChange={(event) => setIsAdmin(event)}/>
                </Form.Item>
                {is_edit_mode()?
                    <Form.Item name="continue" valuePropName="checked">
                        <label style={{ display: 'flex', alignItems: 'center', marginTop: 'auto', marginBottom: 15 }}>
                            <Checkbox
                                checked={keepWindowOpen}
                                onChange={(e) => setKeepWindowOpen(e.target.checked)}
                            />
                            <span style={{ marginLeft: 8 }}>Continue Adding Users</span>
                        </label>
                    </Form.Item>
                    : null
                }
                
            </Form>
        </Modal>
  );
};

export default AddUserModal;
