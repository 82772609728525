import messages from "../locales/en.json"

const english = {
    messages: {
        ...messages,
    },
    locale: 'en',
}

export default english;
