import { fetchErrorAction, fetchStartAction, fetchSuccessAction } from "./creators/common_actions";
import { AppDispatch } from "../store";
import { get, put } from "../../services/axios_wrapper";
import { GroupLog, Log, Status } from "../../models/entities/log";
import { PageResource } from "../../models/dtos/page_resource";

const api_path = '/api/logs/';

export const getLogs = (risks: string[], statuses: string[], assignees: string[], search: string, times: string[], servers: string[], pagination: number) => (dispatch: AppDispatch): Promise<PageResource<GroupLog>> => {
    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getLogs?risks=${risks}&statuses=${statuses}&assignees=${assignees}&search=${search}&times=${times}&servers=${servers}&pagination=${pagination}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getStatuses = () => (dispatch: AppDispatch): Promise<{statuses: Status[]}> => {
    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getStatuses`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getServers = () => (dispatch: AppDispatch): Promise<{servers: string[]}> => {
    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getServers`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const groupLogs = (group_id: string, pagination?: number) => (dispatch: AppDispatch): Promise<PageResource<Log>> => {
    dispatch(fetchStartAction());

    const request: any = get(`${api_path}groupLogs?group_id=${group_id}&pagination=${pagination}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const logDetails = (log_id: string) => (dispatch: AppDispatch): Promise<{log: Log}> => {
    dispatch(fetchStartAction());

    const request: any = get(`${api_path}logDetails?log_id=${log_id}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateLogStatus = (params: {group_id: string, status: string}) => (dispatch: AppDispatch): Promise<any> => {
    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updateLogStatus`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateLogAssignee = (params: {group_id: string, assignee: string}) => (dispatch: AppDispatch): Promise<any> => {
    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updateLogAssignee`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}
