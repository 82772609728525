import {combineReducers} from 'redux';
import Common from "./common";

const rootReducer = combineReducers({
    common: Common,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer;
