import React, { useEffect, useState } from 'react';
import Page from '../../components/page_structure/page';
import PageHeader from '../../components/page_structure/page_header';
import PageContent from '../../components/page_structure/page_content';
import { ColumnType } from 'antd/es/table';
import { AnyObject } from 'antd/es/_util/type';
import TableC from '../../components/tables/table';
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { groupLogs } from '../../redux/actions/log_actions';
import { Log } from '../../models/entities/log';
import { PageResource } from '../../models/dtos/page_resource';
import { createEmptyPage } from '../../services/utils/page_resource_utils';


function GroupLogsPage(): JSX.Element {
    const { group_id } = useParams<{ group_id: string }>();
    
    const navigate = useNavigate();
    
    const dispatch: AppDispatch = useDispatch();

    const [logsPage, setLogsPage] = useState<PageResource<Log>>(createEmptyPage());
    const [currentPagination, setCurrentPagination] = useState<number>(10);
    const [triggerFetch, setTriggerFetch] = useState<boolean>(false)
    const [maxLogs, setMaxLogs] = useState<number>(0);

    useEffect(() => {
        if (group_id) {
            const fetchData = async () => {
                try {
                    const logs = await dispatch(groupLogs(group_id, currentPagination));
                    setLogsPage(logs)
                    setMaxLogs(logs.totalElements)
                } catch (error) {
                    console.error(error);
                }
            };
            fetchData();
        }
    }, [dispatch, triggerFetch]);

    const columns = () => {
        let columns: ColumnType<AnyObject>[] = []
        const defaultColumns: ColumnType<AnyObject>[] = [
        {
            title: "ID",
            dataIndex: "_id",
            key: "_id",
            sorter: (a:any, b:any) => String(a._id).localeCompare(String(b._id)),
            ellipsis: true,
            width: '80px',
        },{
            title: "Group ID",
            dataIndex: "group_id",
            key: "group_id",
            sorter: (a:any, b:any) => String(a.group_id).localeCompare(String(b.group_id)),
            ellipsis: true,
            width: '80px',
        },{
            title: "Server",
            dataIndex: "server",
            key: "server",
            sorter: (a:any, b:any) => String(a.server).localeCompare(String(b.server)),
            ellipsis: true
        },{
            title: "Service",
            dataIndex: "service",
            key: "service",
            sorter: (a:any, b:any) => String(a.service).localeCompare(String(b.service)),
            ellipsis: true,
            width: '150px',
        },{
            title: "Risk",
            dataIndex: "risk",
            key: "risk",
            sorter: (a:any, b:any) => String(a.risk).localeCompare(String(b.risk)),
            ellipsis: true,
            width: '80px',
        },{
            title: "Title",
            dataIndex: "title",
            key: "title",
            sorter: (a:any, b:any) => String(a.title).localeCompare(String(b.title)),
            ellipsis: true,
        },{
            title: "Request",
            dataIndex: "request",
            key: "request",
            sorter: (a:any, b:any) => String(a.request).localeCompare(String(b.request)),
            ellipsis: true
        },{
            title: "Description",
            dataIndex: "description",
            key: "description",
            sorter: (a:any, b:any) => String(a.description).localeCompare(String(b.description)),
            ellipsis: true
        },{
            title: "Error",
            dataIndex: "error",
            key: "error",
            sorter: (a:any, b:any) => String(a.error).localeCompare(String(b.error)),
            ellipsis: true,
            width: '100px',
        },
        {
            title: "Created",
            dataIndex: "time_created",
            key: "time_created",
            sorter: (a:any, b:any) => String(a.time).localeCompare(String(b.time)),
            ellipsis: true
        },{
            title: "Status",
            dataIndex: "status",
            key: "status",
            sorter: (a:any, b:any) => String(a.status).localeCompare(String(b.status)),
            ellipsis: true
        },{
            title: "Assignee",
            dataIndex: "assignee",
            key: "assignee",
            sorter: (a:any, b:any) => String(a.assignee).localeCompare(String(b.assignee)),
            ellipsis: true
        }]

        columns = [...columns, ...defaultColumns]

        // if (true) {
        //     columns.push({
        //         title: <>
        //             <Dropdown
        //                 disabled={
        //                     selected_users === 0 || !true
        //                 }
        //                 menu={{items: [
        //                         {
        //                             key: `mass-delete`,
        //                             label: <DeleteOutlined onClick={handleDelete}><DeleteOutlined /> Delete</DeleteOutlined>,
        //                         }
        //                     ]
        //                 }}
        //             >
        //                 <a>
        //                     <Space>
        //                         Actions <DownOutlined />
        //                     </Space>
        //                 </a>
        //             </Dropdown>
        //             {true ? <div style={{fontSize: 11}}>selected {selected_users}</div> : null}
        //         </>,
        //         width: '170px',
        //         dataIndex: 'actions',
        //         key: 'actions'
        //     })
        // }

        return columns
    }

    const setData = () => {
        let data: any = [];
            logsPage.content.forEach(log => {
                data.push({
                    _id: <a onClick={() => navigate(`/logs/${log.group_id}/${log._id}`)}>{log._id}</a>,
                    group_id: log.group_id,
                    server: log.server,
                    service: log.service,
                    risk: log.risk,
                    title: log.title,
                    request: log.request,
                    description: log.description, 
                    error: log.error, 
                    time_created: log.time_created,
                    status: log.status,
                    assignee: log.assignee})});
  
        return data;
    } 

    return (
        <Page title="Logs">
            <PageHeader title={`Testing Panda - ${group_id} Group Logs`} extraButton={true}/>
                <PageContent>
                    <TableC
                        columns={columns()}
                        data={setData()}
                        checkboxEnabled={false}
                        currentPagination={currentPagination}
                        setCurrentPagination={setCurrentPagination}
                        triggerFetch={triggerFetch}
                        setTriggerFetch={setTriggerFetch}
                        maxLogs={maxLogs}
                    />
                </PageContent>
        </Page>
      );
}

export default GroupLogsPage;
