import React from 'react';
import { Button, Typography } from 'antd';
import { LogoutOutlined, SettingOutlined, SwapLeftOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserContext } from '../../contexts/userContext';
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/actions/users_actions';

type PageHeaderProps = {
    title: React.ReactNode;
    hasPermissions?: boolean;
    extraButton?: boolean;
};

function PageHeader({
    title,
    hasPermissions = false,
    extraButton = false,  // Default navigation path
}: PageHeaderProps): JSX.Element {

    const location = useLocation();

    const dispatch: AppDispatch = useDispatch();

    const { resetApp, loggedInUser } = useUserContext();

    const navigate = useNavigate();

    const has_permission = () => {
        if (loggedInUser.role !== 3) {
            hasPermissions = true;
        }
        return hasPermissions;
    };

    const headerStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px 20px 10px',
        backgroundColor: '#f7f9fc',
        borderBottom: '1px solid #e0e0e0',
    };

    const titleStyle = {
        margin: 0,
        color: '#2c3e50',
        fontSize: '24px',
        fontWeight: 600,
    };

    const buttonStyle = {
        backgroundColor: '#3498db',
        color: 'white',
        border: 'none',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
    };

    const buttonHoverStyle = {
        backgroundColor: '#2980b9',
    };

    const handleLogout = () => {
        dispatch(logout())
            .then(() => {
                resetApp()
                navigate('/login')
            }).catch((error) => {
                console.log(error)
            })
    }

    return (
        <div style={headerStyle}>
            <Typography.Title level={2} style={titleStyle}>
                {title}
            </Typography.Title>
            <div style={{ display: 'flex', gap: '10px' }}>
                {has_permission() && location.pathname !== `/manage` && (
                    <Button
                        onClick={() => navigate(`/manage`)}
                        shape="circle"
                        size="large"
                        icon={<SettingOutlined />}
                        title={'Manage Users'}
                        style={buttonStyle}
                        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor)}
                        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor)}
                    />
                )}
                {extraButton && <Button
                        onClick={() => navigate(`/logs`)}
                        shape="circle"
                        size="large"
                        icon={<SwapLeftOutlined />}
                        title={'Back to Logs'}
                        style={buttonStyle}
                        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor)}
                        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor)}
                    />}
                <Button
                    onClick={handleLogout}
                    shape="circle"
                    size="large"
                    icon={<LogoutOutlined />}
                    title={"Logout"}
                    style={buttonStyle}
                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor)}
                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor)}
                />
            </div>
        </div>
    );
}

export default PageHeader;
